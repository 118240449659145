* {
    //-moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
html {
    font-size: 62.5%; // 10px

    @media screen and (min-width: $breakpoint-2) {
	font-size: 75%; // 12px
    }

    @media screen and (min-width: $breakpoint-2) {
	font-size: 87.5%; // 14px
    }

    @media screen and (min-width: $breakpoint-3) {
	font-size: 100%; // 16px
    }
}
body {
    font-size: $scale0;
    font-family: $font;
    line-height: $leading;

    color: $text-colour;
    background-color: $body-bg-colour;
    background-image: url(../images/bg-sky.gif);

    height: 100vh;
    overflow: hidden;
    @include flexify(column, $align: center, $justify: center);

    cursor: url(../images/cur-norm.png), auto;
}

area:hover {
    cursor: url(../images/cur-cont.png), pointer;
}

main {
    height: 100vh;
    width: 100vh;

    display: grid;
    grid-template-rows: repeat(60, 1fr);
    grid-template-columns: repeat(60, 1fr);

    &#pwd {
	display: flex;
	align-items: center;
	justify-content: center;

	img {
	    max-width: 100%;
	    max-height: 100%;
	}
    }

    .tile {
	img {
	    width: 100%;
	    height: 100%;
	    opacity: 1;
	    @include prefixer((transition: opacity 1s ease), webkit moz ms o);
	}

	&[data-content] {
	    cursor: url(../images/cur-cont.png), pointer;
	}

	&[data-offering="marigold"] > img {
	    clip-path: polygon(0px 0px, -2.85% 40.2%, 6px 64.65%, 21.45% 63.39%, 22.46% 99.75%, 35.02% 104%, 45.98% 94.08%, 59.04% 108.79%, 75.64% 93.49%, 83.08% 72.55%, 90.01% 67.17%, 102.07% 54.96%, 101.26% 30.8%, 101.83% 13.00%, 90.58% 8.19%, 75.43% 35.13%, 66.88% 52.49%, 54.66% 64.65%, 36.1% 57.35%, 25.21% 43.57%, 17.18% 20.51%);
	    cursor: url(../images/cur-cont.png), pointer;
	}

	&[data-offering="snake"] > img {
	    clip-path: polygon(25px 66px, 23.79% 47.97%, 34px 55.77%, 34.77% 71.71%, 43.33% 76.42%, 58.98% 74.95%, 73.15% 68.9%, 88.53% 69.63%, 87.19% 61.92%, 94.88% 60.85%, 104.09% 73.66%, 86.87% 79.07%, 72.45% 80.26%, 64.08% 83.1%, 50.51% 87.14%, 37.07% 68.46%, 36.01% 53.16%, 28.2% 41.65%, 33.36% 35.74%, 39.32% 34.15%, 52.6% 34.65%, 57.33% 38.55%, 68.09% 36.37%, 81.69% 29.99%, 87.09% 26.1%, 92.94% 16.37%, 92.24% 2.09%, 78.33% 7.79%, 76.81% 23.08%, 63.82% 28.58%, 50.25% 30.16%, 37.16% 32.16%);
	    cursor: url(../images/cur-cont.png), pointer;
	}

	&[data-offering="rose"] > img {
	    clip-path: polygon(-3px 41px, 1px 64.64%, 28.42% 62.18%, 29.07% 80.81%, 41.27% 90.87%, 49.09% 85.57%, 42.04% 57.6%, 52.52% 66.34%, 56.54% 76.26%, 70.59% 76.91%, 75.43% 70.96%, 84.88% 66.99%, 87.87% 53.21%, 84.96% 41.12%, 83.53% 19.54%, 70.18% 11.03%, 57.83% 15.25%, 49.72% 22.14%, 39.12% 19.57%, 41.94% 33.65%, 47.84% 50.7%, 31.78% 51.21%);
	    cursor: url(../images/cur-cont.png), pointer;
	}

	&[data-offering="candle"] {
	    //z-index: 900;

	    > img {
		clip-path: polygon(34.95% 26.36%, 33.09% 42.12%, 39.04% 56.83%, 7.89% 58.6%, 10.07% 74.13%, 21.54% 74.89%, 67.47% 75.94%, 80.69% 67.99%, 74.22% 62.18%, 81.8% 51.98%, 88.63% 61.95%, 92.19% 68.01%, 98.12% 62.11%, 98.37% 53.55%, 79.83% 38.51%, 67.79% 52.39%, 57.89% 58.14%, 59.07% 40.18%, 64.09% 26.04%);
		cursor: url(../images/cur-cont.png), pointer;

		&.on {
		    clip-path: polygon(54px -5px, 37.2% 6.02%, 34.95% 26.36%, 33.09% 42.12%, 39.04% 56.83%, 7.89% 58.60%, 10.07% 74.13%, 21.54% 74.89%, 67.47% 75.94%, 80.69% 67.99%, 74.22% 62.18%, 81.8% 51.98%, 88.63% 61.95%, 92.19% 68.01%, 98.12% 62.11%, 98.37% 53.55%, 79.83% 38.51%, 67.79% 52.39%, 57.89% 58.14%, 59.07% 40.18%, 64.09% 26.04%, 63.71% 10.47%);
		}
	    }
	} 

	&[data-offering="banana"] > img {
	    clip-path: polygon(19px 26px, 5.15% 56.44%, 9.85% 70.81%, 18px 89.4%, 39.09% 93.02%, 61.3% 88.54%, 72.93% 82.59%, 78.87% 76.1%, 87.32% 59.24%, 91.52% 35.51%, 85.36% 10.94%, 65.86% -1.89%, 62.88% 17.55%, 53.97% 34.42%, 41.55% 37.12%);
	    cursor: url(../images/cur-cont.png), pointer;
	}

	&[data-offering="maryam_ka_phool"] > img {
	    cursor: url(../images/cur-cont.png), pointer;
	}

	&[data-id="search-start"] > img {
	    cursor: url(../images/cur-cont.png), pointer;
	}

	&[data-id="circuit-board-for-video"] > img {
	    cursor: url(../images/cur-cont.png), pointer;
	}
    }

    #reveal {
	//background-color: $white;
	color: $black;
	grid-column: 23/39;
	grid-row: 30/33;
	opacity: 0;

	@include flexify($align: center, $justify: center);
	//@include prefixer((transition: opacity 1s ease), webkit moz ms o);
    }

    #faces {
	grid-column: 29/33;
	grid-row: 13/17;
	width: 100%;
	opacity: 0;
	@include prefixer((transition: opacity 1s ease), webkit moz ms o);
	cursor: url(../images/cur-cont.png), pointer;

	&.playing {
	    opacity: 1;
	}

	&.sp2 {
	    z-index: 1001;
	    grid-column: 27/31;
	    grid-row: 31/35;
	}

	&.sp3 {
	    z-index: 1001;
	    grid-column: 38/42;
	    grid-row: 51/55;
	}
    }
}



#overlord {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    z-index: 0;
    background-color: rgba(0,0,0,0.8);

    @include flexify($align: center, $justify: center);
    @include prefixer((transition: opacity 1s ease), webkit moz ms o);
    display: none;

    &.possessed {
	display: flex;
	opacity: 1;
	z-index: 1000;
    }

    #close-btn {
	position: absolute;
	top: $spacing;
	left: $spacing;

	font-size: $scale3;
	color: $white;
	cursor: pointer;

	&:hover {
	    color: $grey;
	}
    }

    #oc {
	height: 100vh;
	width: 100vh;
	@include flexify($align: center, $justify: center);

	img, video {
	    width: 95%;
	}

	&.offered {
	    display: block;
	    position: relative;

	    img {
		width: auto;
	    }
	}
    }
}

#generate {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url(../images/search/search-sky.jpg);
    color: $white;

    @include flexify($align: center, $justify: center);
    display: none;

    .close-btn {
	position: absolute;
	top: $spacing;
	right: $spacing;

	font-size: $scale3;
	color: $white;
	cursor: pointer;

	&:hover {
	    color: $grey;
	}
    }

    form {
	height: 95vh;
	width: 95vh;

	display: grid;
	grid-template-rows: repeat(60, 1fr);
	grid-template-columns: repeat(60, 1fr);

	//font-size: $scale2;

	h1 {
	    //font-size: $scale2;
	}

	img {
	    width: 100%;
	}

	label {

	    strong {
		color: #088b0e;
		//font-size: $scale3;
		display: block;
		line-height: 1;
	    }
	}

	textarea, input[type=text], input[type=email] {
	    border: 1px solid #088b0e;
	    background: transparent;
	    color: $white;
	}

	button {
	    outline: 0;
	    border: 0;
	    background: transparent;
	    cursor: pointer;
	}

	.gen-page-frame {
	    grid-column: 1/60;
	    grid-row: 1/60;
	    background-color: $black;
	    border: 1px solid $white;
	}

	.gen-page-1 {
	    //display: none;
	}

	.gen-page-2 {
	    display: none;
	}

	.gen-page-3 {
	    display: none;
	}

	#gen-page-1-frame {
	    grid-column: 3/59;
	    grid-row: 5/58;
	    width: 100%;
	    height: 100%;
	}

	#gen-page-1-title {
	    grid-column: 5/19;
	    grid-row: 6/8;
	}

	#gen-page-1-share-label {
	    grid-column: 5/33;
	    grid-row: 10/14;
	}

	#gen-page-1-share {
	    grid-column: 5/57;
	    grid-row: 14/27;
	}

	#gen-page-1-mailto {
	    grid-column: 5/40;
	    grid-row: 28/30;
	}

	#gen-page-1-ask-label {
	    grid-column: 5/40;
	    grid-row: 31/32;
	}

	#gen-page-1-ask {
	    grid-column: 5/57;
	    grid-row: 33/46;
	}

	#gen-page-1-email-label {
	    line-height: 1;
	    grid-column: 7/18;
	    grid-row: 53/56;
	}

	#gen-page-1-email {
	    grid-column: 19/36;
	    grid-row: 54/56;
	    z-index: 3;
	}

	#gen-page-1-generate {
	    grid-column: 10/35;
	    grid-row: 47/52;
	    color: $white;
	    text-decoration: none;
	}

	#gen-page-1-submit {
	    grid-column: 44/53;
	    grid-row: 50/53;
	    background: url(../images/search/submit.jpg) 0 0 no-repeat;
	    background-size: contain;
	    z-index: 2;
	}


	#gen-page-2-title {
	    grid-column: 4/43;
	    grid-row: 4/7;
	    color: #088b0e;
	}

	#gen-page-2-chat-box {
	    grid-column: 35/58;
	    grid-row: 8/31;
	}

	#gen-page-2-dialog-1 {
	    grid-column: 12/30;
	    grid-row: 23/32;
	}

	.gen-page-2-err {
	    opacity: 0;

	    &.current {
		opacity: 1;
	    }
	}

	#gen-page-2-err-0 {
	    grid-column: 16/29;
	    grid-row: 23/33;
	}

	#gen-page-2-err-1 {
	    grid-column: 9/30;
	    grid-row: 23/32;
	}

	#gen-page-2-err-2 {
	    grid-column: 9/30;
	    grid-row: 23/32;
	}

	#gen-page-2-generated {
	    grid-column: 33/44;
	    grid-row: 32/40;
	}

	#gen-page-2-logo {
	    grid-column: 14/48;
	    grid-row: 43/49;
	}

	#gen-page-2-search {
	    grid-column: 13/49;
	    grid-row: 50/56;
	}

	#gen-page-2-q-label {
	    grid-column: 39/49;
	    grid-row: 57/59;
	}

	#gen-page-2-q {
	    grid-column: 14/41;
	    grid-row: 51/55;
	    font-family: $font;
	    font-size: $scale2;
	    background-color: transparent;
	    border: none;
	    outline: 0;
	    color: $white;
	    z-index: 100;
	}

	#gen-page-2-disallowed {
	    grid-column: 13/49;
	    grid-row: 56/60;
	    background-color: $white;
	    padding: 0 $spacing-sm;
	    overflow: scroll;
	    color: $black;
	    height: 0;
	    @include prefixer((transition: height 0.5s ease), webkit moz ms o);

	    &.isopen {
		height: 100%;
	    }
	}



	#gen-page-3-title {
	    grid-column: 4/58;
	    grid-row: 4/6;
	    color: #088b0e;
	    text-align: right;
	}

	#gen-page-3-logo {
	    grid-column: 4/20;
	    grid-row: 4/7;
	}

	#gen-page-3-name {
	    grid-column: 21/58;
	    grid-row: 4/7;

	    span {
		background-color: $white;
		color: $black;
		padding: $spacing-sm;
	    }
	}

	#gen-page-3-look-label {
	    grid-column: 4/36;
	    grid-row: 8/10;
	}

	#gen-page-3-look {
	    grid-column: 4/58;
	    grid-row: 11/24;
	}

	#gen-page-3-story-label {
	    grid-column: 4/52;
	    grid-row: 25/28;
	}

	#gen-page-3-story {
	    grid-column: 4/58;
	    grid-row: 28/41;
	}

	#gen-page-3-dialog-box {
	    grid-column: 4/42;
	    grid-row: 44/53;
	}

	#gen-page-3-dialog-label {
	    grid-column: 44/55;
	    grid-row: 44/50;
	    text-align: right;
	}

	#gen-page-3-dialog {
	    grid-column: 5/41;
	    grid-row: 45/49;
	    background-color: transparent;
	    border: none;
	    outline: 0;
	    color: $white;
	    z-index: 100;
	}

	#gen-page-3-email-label {
	    line-height: 1;
	    grid-column: 7/18;
	    grid-row: 53/56;
	}

	#gen-page-3-email {
	    grid-column: 19/36;
	    grid-row: 54/56;
	    z-index: 3;
	}

	#gen-page-3-submit {
	    grid-column: 47/56;
	    grid-row: 52/55;
	    background: url(../images/search/submit.jpg) 0 0 no-repeat;
	    background-size: cover;
	}

	#gen-page-3-msg {
	    grid-column: 8/54;
	    grid-row: 57/59;
	    text-align: center;
	    font-size: $scale1;
	}
    }
}
