// typography
 @import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');

$font: Aldrich, 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-scale-factor: 1.2;
$scale0: 1rem;
$scale-1: $scale0 / $font-scale-factor;
$scale-2: $scale-1 / $font-scale-factor;
$scale1: $scale0 * $font-scale-factor;
$scale2: $scale1 * $font-scale-factor;
$scale3: $scale2 * $font-scale-factor;
$scale4: $scale3 * $font-scale-factor;
$scale5: $scale4 * $font-scale-factor;

$leading: 1.5;
$leading-sm: 1.2;
$leading-lg: 2;

$character-width: 40ch;


// colours
$black: #000;
$white: #fff;
$grey: darken($white, 50%);
$dark-grey: darken($white, 75%);
$light-grey: darken($white, 25%);
$lighter-grey: darken($white, 10%);

$primary-colour: #a8dadc;
$secondary-colour: #457b9d;
$complementary-colour: #e63946;

$body-bg-colour: $white;
$header-bg-colour: $primary-colour;
$footer-bg-colour: $secondary-colour;

$text-colour: $dark-grey;
$heading-colour: $black;
$link-colour: $complementary-colour;
$link-hover-colour: darken($link-colour, 25%);
$link-active-colour: $primary-colour;
$border-colour: $lighter-grey;
$overlay-colour: rgba(0,0,0,0.8);

$focus-colour: blue;
$error-colour: red;


// layout
$container-max-width: 1440px;


// breakpoints
// em based media queries are always based on the browser default font size (16px or set by user)
$breakpoint-1: 25em; // 400px
$breakpoint-2: 50em; // 800px
$breakpoint-3: 64em; // 1024px
$breakpoint-4: 90em; // 1440px


// spacing
$spacing: 1em;
$spacing-sm: 0.5em;
$spacing-xs: 0.25em;
$spacing-xxs: 0.125em;
$spacing-lg: 1.5em;


