/*
Title: Our Lady of CSS
Description: Art project for Afrah Shafiq
Author: Prathamesh Pai
Author URI: http://transducent.com/
Version: 1.0.0
*/

// required modules: edit these if needed
@import 'vars';
@import 'reset';
@import 'mixins';
@import 'base';

// site specific styles: removed or edit as needed
@import 'site';

